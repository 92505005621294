import React from 'react'

function BootcampSummary() {
  return (
    <div className='bootcamp-summary-section'>
        
        <svg xmlns="http://www.w3.org/2000/svg" width="181" height="192" viewBox="0 0 181 192" fill="none">
            <path d="M0 43.9478L20.5542 185.977L180.378 148.028L159.824 5.99869L0 43.9478ZM42.4834 156.305L63.5976 128.935L36.2279 113.079L40.1647 107.976L72.6376 126.788L47.5866 159.261L42.4834 156.305ZM110.92 145.086L71.9494 154.34L71.1246 148.64L110.096 139.387L110.92 145.086Z" fill="#E8F2E5"/>
        </svg>
        
        <div className='bootcamp-summary-div'>
            <div className='bootcamp-image-summary'>
                <svg xmlns="http://www.w3.org/2000/svg" width="76" height="49" viewBox="0 0 76 49" fill="none">
                    <path d="M48.9219 15.6834L70.5232 3.25409C73.3828 1.57813 75.75 2.76172 75.75 6.21466V42.9061C75.75 46.2612 73.3828 47.6404 70.5232 45.9645L48.9219 33.4373V42.7104C48.9219 46.1633 46.1602 48.925 42.7072 48.925H6.01581C2.66072 48.925 0 46.1633 0 42.7104V6.11681C0 2.66387 2.66387 0 6.01581 0H42.7072C46.1602 0 48.9219 2.66387 48.9219 6.11681V15.6834Z" fill="#FFC300"/>
                </svg>
            </div>
            <div className='bootcamp-summary-div-texts'>
                <h5>Weekly Classes Over Zoom</h5>
                <h6>Every Tuesday and Thursday</h6>
                {/*<h6>Every Tuesday and Thursday from 2pm to 3:30pm (PST)</h6>*/}
            </div>
        </div>
        <div className='bootcamp-summary-div'>
            <div className='bootcamp-image-summary'>
                <svg width="42" height="66" viewBox="0 0 42 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.8213 58.0693C10.8213 55.9307 11.5537 54.0996 13.0186 52.5762C14.5127 51.0234 16.6514 50.2471 19.4346 50.2471C22.2471 50.2471 24.3857 51.0234 25.8506 52.5762C27.3447 54.0996 28.0918 55.9307 28.0918 58.0693C28.0918 60.208 27.3447 62.0537 25.8506 63.6064C24.3857 65.1299 22.2617 65.8916 19.4785 65.8916C16.7246 65.8916 14.5859 65.1299 13.0625 63.6064C11.5684 62.0537 10.8213 60.208 10.8213 58.0693ZM0.538086 19.793V16.8926C0.538086 11.8828 2.28125 7.8252 5.76758 4.71973C9.25391 1.61426 14.1904 0.0615234 20.5771 0.0615234C27.4326 0.0615234 32.6475 1.43848 36.2217 4.19238C39.8252 6.91699 41.627 10.5205 41.627 15.0029C41.627 19.3096 40.8799 22.5908 39.3857 24.8467C37.9209 27.1025 35.8994 29.4756 33.3213 31.9658C30.8311 34.3682 29.1025 36.3604 28.1357 37.9424C27.1982 39.4951 26.7295 41.165 26.7295 42.9521V44.0508H12.0518V42.4688C12.0518 39.4512 12.6523 36.8438 13.8535 34.6465C15.084 32.4492 17.1934 30.0176 20.1816 27.3516C22.6133 25.1543 24.166 23.4258 24.8398 22.166C25.543 20.9062 25.8945 19.251 25.8945 17.2002C25.8945 15.1201 25.4258 13.5088 24.4883 12.3662C23.5508 11.2236 22.042 10.6523 19.9619 10.6523C17.8818 10.6523 16.3291 11.3262 15.3037 12.6738C14.2783 14.0215 13.7656 16.0576 13.7656 18.7822V19.793H0.538086Z" fill="#FFC300"/>
                </svg>
            </div>
            <div className='bootcamp-summary-div-texts'>
                <h5>Discord Community</h5>
                <h6>
                    Engage with your peers, showcase your journey, and seek insights through interactive discussions with instructors.
                </h6>
            </div>
        </div>
        <div className='bootcamp-summary-div'>
            <div className='bootcamp-image-summary'>
                <svg xmlns="http://www.w3.org/2000/svg" width="57" height="71" viewBox="0 0 57 71" fill="none">
                    <path d="M24.0451 0H32.9735C37.2172 0 40.6908 3.28357 40.9729 7.43892L40.9914 7.98567L40.9913 8.87296H45.4185C51.8148 8.87296 57 14.0373 57 20.4078V63.0143C57 67.4247 53.4102 71 48.982 71H8.01795C3.58976 71 0 67.4247 0 63.0143V20.4078C0 14.0373 5.18521 8.87296 11.5815 8.87296H16.0252L16.0271 7.98567C16.0271 3.75907 19.324 0.299392 23.4961 0.0184233L24.0451 0ZM40.0918 40.8156H16.9349C13.663 40.8156 10.9796 43.3253 10.7194 46.5173L10.6987 47.0267V54.1148C10.6987 57.3735 13.2185 60.0461 16.4234 60.3052L16.9349 60.3258H40.0918C43.3638 60.3258 46.0472 57.8162 46.3073 54.6242L46.328 54.1148V47.0267C46.328 43.7679 43.8082 41.0953 40.6033 40.8362L40.0918 40.8156ZM16.9349 46.1394H40.0918C40.5135 46.1394 40.8668 46.4313 40.9592 46.8233L40.9827 47.0267V54.1148C40.9827 54.5348 40.6897 54.8867 40.2961 54.9786L40.0918 55.002H16.9349C16.5132 55.002 16.1599 54.7102 16.0676 54.3182L16.044 54.1148V47.0267C16.044 46.6067 16.3371 46.2548 16.7306 46.1628L16.9349 46.1394ZM32.9627 26.6189H24.0538L23.6912 26.6432C22.3867 26.8195 21.3812 27.9332 21.3812 29.2808C21.3812 30.6284 22.3867 31.7421 23.6912 31.9184L24.0538 31.9427H32.9627L33.3253 31.9184C34.6299 31.7421 35.6353 30.6284 35.6353 29.2808C35.6353 27.9332 34.6299 26.8195 33.3253 26.6432L32.9627 26.6189ZM32.9735 5.32378H24.0451C22.692 5.32378 21.5738 6.32519 21.3968 7.62446L21.3724 7.98567L21.3705 8.87296H35.6461V7.98567C35.6461 6.63806 34.6407 5.52434 33.3361 5.34808L32.9735 5.32378Z" fill="#FFC300"/>
                </svg>
            </div>
            <div className='bootcamp-summary-div-texts'>
                <h5>Weekly Homework</h5>
                <h6>Every class you'll have multiple exercises to <br/> practice and problem sets to submit.</h6>
            </div>
        </div>
    </div>
  )
}

export default BootcampSummary