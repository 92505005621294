import React, { useEffect } from 'react'
import NavBar from '../../components/NavBar/NavBar';
import './BootcampPage.css'
import { trackMixpanel } from '../../mixpanelWrapper';
import BootcampHeader from '../../components/Bootcamp/BootcampHeader/BootcampHeader';
import BootcampFormPayment from '../../components/Bootcamp/BootcampForm/BootcampFormPayment';
import BootcampContentPayment from '../../components/Bootcamp/BootcampContent/BootcampContentPayment';
import BootcampBenefits from '../../components/Bootcamp/BootcampBenefits/BootcampBenefits';
import BootcampVideo from '../../components/Bootcamp/BootcampVideo/BootcampVideo';
import BootcampSyllabus from '../../components/Bootcamp/BootcampSyllabus/BootcampSyllabus';
import BootcampCurriculum from '../../components/Bootcamp/BootcampCurriculum/BootcampCurriculum';
import BootcampSummary from '../../components/Bootcamp/BootcampSummary/BootcampSummary';
import BootcampTestimonials from '../../components/Bootcamp/BootcampTestimonials/BootcampTestimonials';
import BootcampFAQ from '../../components/Bootcamp/BootcampFAQ/BootcampFAQ';

function BootcampPage() {
    useEffect(() => {
        trackMixpanel('BootcampVisit');
    }, []);

    return (
        <div className='bootcamp-screen'>
            <NavBar />
            <BootcampHeader />
            <BootcampFormPayment targetDate="2024-08-28" />
            <BootcampContentPayment />
            <BootcampBenefits />
            <BootcampVideo />
            <BootcampSyllabus />
            <BootcampFormPayment targetDate="2024-08-28" />
            <BootcampCurriculum />
            <BootcampSummary />
            <BootcampTestimonials />
            <BootcampFormPayment targetDate="2024-08-28" />
            <BootcampFAQ />
        </div>
    )
}

export default BootcampPage