import React, { useState } from 'react'
import newCodingDorsLogo from '../../images/newCodingDorsLogo.webp';
import './NavBar.css'

function NavBar() {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [productsDropdown, setProductsDropdown] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    const clickProductsDropdown = () => {
        setProductsDropdown(!productsDropdown)
    }

    return (
        <nav className="new-navbar-container">
            <div className='new-navbar-items'>
                <a href='/'>
                    <img src={newCodingDorsLogo} alt="CodingDors" className='newCodingDorsIcon' />
                </a>
                <div className='navbar-private-tutoring-card'>
                    <a href="/bootcamp">Bootcamp</a>
                    <a href="/coachingplans">Private Tutoring</a>
                    <a href="/python">Exercises</a>
                    <a href="/testimonials">Testimonials</a>
                    <a href="/contact">Contact</a>
                    {/* <a href="/landingpage">Ebook</a> */}
                    <p onClick={clickProductsDropdown}>
                        {productsDropdown ? 
                            <i class="fa-solid fa-caret-up" style={{ color: 'white', marginRight: '5px' }}></i>
                        : 
                            <i class="fa-solid fa-caret-down" style={{ color: 'white', marginRight: '5px' }}></i>
                        }
                        Products
                    </p>
                    {productsDropdown && (
                        <div className='navbar-dropdown-menu' style={{ 'zIndex': 100 }}>
                            <a href="/coachingplans" style={{ 'zIndex': 100 }}>Private Tutoring</a>
                            <a href="/bootcamp" style={{ 'zIndex': 100 }}>Bootcamp</a>
                            <a href="/codehelp" style={{ 'zIndex': 100 }}>Code Help!</a>
                            <a href="https://agency.codingdors.com/" target="_blank" rel="noreferrer" style={{ 'zIndex': 100 }}>Agency</a>
                            {/* <a href="/quiz/1">Quiz</a> */}
                        </div>
                    )}
                    
                </div>
                <div className='navbar-mobile-icon' onClick={toggleDropdown}>
                    <i className="fa-solid fa-bars" style={{ color: 'white' }}></i>
                </div>
                {dropdownVisible && (
                    <div className='navbar-dropdown-menu'>
                        <a href="/coachingplans">Private Tutoring</a>
                        <a href="/bootcamp">Bootcamp</a>
                        <a href="/codehelp">Code Help!</a>
                        <a href="/landingpage">Ebook</a>
                        <a href="/python">Exercises</a>
                        <a href="https://agency.codingdors.com/" target="_blank" rel="noreferrer">Agency</a>
                        <a href="/testimonials">Testimonials</a>
                        <a href="/contact">Contact</a>
                        {/* <a href="/quiz/1">Quiz</a> */}
                    </div>
                )}
            </div>
        </nav>
  )
}

export default NavBar