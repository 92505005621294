import React, { useEffect } from 'react'
import { trackMixpanel } from '../../mixpanelWrapper';
// Import components
import TestimonyCard from '../../components/LandingPage/TestimonyCards/TestimonyCard'
import NavBar from '../../components/NavBar/NavBar';
// Import images
import ryanVideoPlaceholderImage from '../../images/landingPageRyanPlaceholder.webp';
import alexVideoPlaceholderImage from '../../images/landingPageAlexPlaceholder.webp';
import elieVideoPlaceholderImage from '../../images/landingPageEliePlaceholder.webp';
import vincentVideoPlaceholderImage from '../../images/landingPageVincentPlaceholder.webp';
import AntoinePlaceholderImage from '../../images/bootcamp/AntoinePlaceholder.webp'
import VictorPlaceholderImage from '../../images/bootcamp/VictorPlaceholder.webp'
import SKPlaceholderImage from '../../images/bootcamp/SKPlaceholder.webp'
import TomasPlaceholderImage from '../../images/Testimonials/tomas.webp'
import LilyPlaceholderImage from '../../images/Testimonials/lily.webp'
import JasonPlaceholderImage from '../../images/Testimonials/google-review-jason.webp'
import TeresaPlaceholderImage from '../../images/Testimonials/google-review-teresa.webp'

function TestimonyPage() {
    useEffect(() => {
        trackMixpanel('VisitTestimonialPage');
      }, []);
    return (
        <div>
            <NavBar />
            <div className='landing-page-white-background'>
                <div className='landing-page-what-costumers-say-container'>
                    <p className='landing-page-what-costumers-say'>What Customers Say</p>
                </div>
                {/* New row of testimonials */}
                <div className='landing-page-testimony-cards-container'>
                    <div className='landing-page-top-left-card'>
                        <TestimonyCard name='Elie Alchaer' occupation='Associate Director at SmartLabs' video='https://www.youtube.com/embed/ocxQb1l8kL4' videoPlaceholderImage={elieVideoPlaceholderImage}/>
                    </div>
                    <div className='landing-page-top-right-card'>
                        <TestimonyCard name='Victor Gallardo' occupation='USA' video='https://www.youtube.com/embed/6SbEGQ9Wg5k' videoPlaceholderImage={VictorPlaceholderImage}/>
                    </div>
                </div>
                {/* New row of testimonials */}
                <div className='landing-page-testimony-cards-container'>
                    <div className='landing-page-bottom-left-card'>
                        <TestimonyCard name='SK Soh' occupation='Singapore' video='https://www.youtube.com/embed/quC1EKSRu_Y' videoPlaceholderImage={SKPlaceholderImage}/>
                    </div>
                    <div className='landing-page-bottom-right-card'>
                        <TestimonyCard name='Antoine Nitzki' occupation='Spain' video='https://www.youtube.com/embed/mVnilLoxMW0' videoPlaceholderImage={AntoinePlaceholderImage}/>
                    </div>
                </div>
                {/* New row of testimonials */}
                <div className='landing-page-testimony-cards-container'>
                    <div className='landing-page-top-left-card'>
                        <TestimonyCard name='Ryan Robinson' occupation='Data Engineer at Microsoft' video='https://www.youtube.com/embed/AMRzSyHFpTE' videoPlaceholderImage={ryanVideoPlaceholderImage}/>
                    </div>
                    <div className='landing-page-top-right-card'>
                        <TestimonyCard name='Alex Tsuzuki' occupation='Senior Consultant at BTS' video='https://www.youtube.com/embed/wpQv3Sndwl8' videoPlaceholderImage={alexVideoPlaceholderImage}/>
                    </div>
                </div>
                {/* New row of testimonials */}
                <div className='landing-page-testimony-cards-container'>
                    <div className='landing-page-bottom-left-card'>
                        <TestimonyCard name='Tomás Coutada' occupation='Industrial Engineer ITBA' video='https://www.youtube.com/embed/6WWZMQ6vu-Q' videoPlaceholderImage={TomasPlaceholderImage}/>
                    </div>
                    <div className='landing-page-bottom-right-card'>
                        <TestimonyCard name='Vincent Klimjack' occupation='Industrial Engineer at Woocar' video='https://www.youtube.com/embed/f9WD5jgNW9E' videoPlaceholderImage={vincentVideoPlaceholderImage}/>
                    </div>
                </div>
                {/* New row of testimonials */}
                <div className='landing-page-testimony-cards-container'>
                    <div className='landing-page-top-left-card'>
                        <TestimonyCard name='Lily' occupation='USA' video='https://www.youtube.com/embed/rot1gT6j3ow' videoPlaceholderImage={LilyPlaceholderImage}/>
                    </div>
                    <div className='landing-page-top-right-card'>
                        <div className="landing-page-testimony-card">
                            <img src={JasonPlaceholderImage} alt='Google Review - Jason' />
                        </div>
                    </div>
                </div>
                <div className='landing-page-testimony-cards-container-2'>
                    <div className="landing-page-testimony-card">
                        <img src={TeresaPlaceholderImage} alt='Google Review - Teresa' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestimonyPage