import React, { useEffect } from 'react'
import './LandingPage.css'
import NavBar from '../../components/NavBar/NavBar';
import FormCard from '../../components/LandingPage/FormCard/FormCard'
import TopSessionInfo from '../../components/LandingPage/TopSessionInfo/topSessionInfo'
import TestimonyCard from '../../components/LandingPage/TestimonyCards/TestimonyCard'
import landingPageBackgroundLines from '../../images/landingPageBackgroundLines.webp'
import ryanVideoPlaceholderImage from '../../images/landingPageRyanPlaceholder.webp';
import alexVideoPlaceholderImage from '../../images/landingPageAlexPlaceholder.webp';
import elieVideoPlaceholderImage from '../../images/landingPageEliePlaceholder.webp';
import vincentVideoPlaceholderImage from '../../images/landingPageVincentPlaceholder.webp';
import { trackMixpanel } from '../../mixpanelWrapper';

function LandingPage() {
  useEffect(() => {
    trackMixpanel('VisitLandingPage');
  }, []);

  return (
    <div className='landing-page-screen'>
      <NavBar />
      <div className='landing-page-gray-background-top'>
        <div>
          <img className='landing-page-background-lines' src={landingPageBackgroundLines} alt=''></img>
        </div>
        <div className='landing-page-top-session-left'>
          <TopSessionInfo />
        </div>
        <div className='landing-page-top-session-right'>
          <FormCard />
        </div>
      </div>
      <div className='landing-page-white-background'>
        <div className='landing-page-what-costumers-say-container'>
          <p className='landing-page-what-costumers-say'>What Customers Say</p>
        </div>
        <div className='landing-page-testimony-cards-container'>
          <div className='landing-page-top-left-card'>
            <TestimonyCard name='Ryan Robinson' occupation='Data Engineer at Microsoft' video='https://www.youtube.com/embed/AMRzSyHFpTE' videoPlaceholderImage={ryanVideoPlaceholderImage}/>
          </div>
          <div className='landing-page-top-right-card'>
            <TestimonyCard name='Alex Tsuzuki' occupation='Senior Consultant at BTS' video='https://www.youtube.com/embed/wpQv3Sndwl8' videoPlaceholderImage={alexVideoPlaceholderImage}/>
          </div>
        </div>
        <div className='landing-page-testimony-cards-container'>
          <div className='landing-page-bottom-left-card'>
            <TestimonyCard name='Elie Alchaer' occupation='Associate Director at SmartLabs' video='https://www.youtube.com/embed/ocxQb1l8kL4' videoPlaceholderImage={elieVideoPlaceholderImage}/>
          </div>
          <div className='landing-page-bottom-right-card'>
            <TestimonyCard name='Vincent Klimjack' occupation='Industrial Engineer at Woocar' video='https://www.youtube.com/embed/f9WD5jgNW9E' videoPlaceholderImage={vincentVideoPlaceholderImage}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPage