import React, { useEffect, useState } from 'react';

const CountdownTimer = ({ targetDate }) => {
    const calculateTimeLeft = () => {
        const difference = +new Date(targetDate) - +new Date();
        let timeLeft = {};

        if (difference > 0) {
        timeLeft = {
            days: Math.floor(difference / (1000 * 60 * 60 * 24)),
            hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60),
        };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearTimeout(timer);
    });

    return (
        <div className='bootcamp-countdown-timer-div'>
            {Object.keys(timeLeft).length > 0 && (
                <>
                    <div className='bootcamp-countdown-timer-square'>
                        <h2>{timeLeft.days}</h2>
                        <p>DAYS</p>
                    </div>

                    <div className='bootcamp-countdown-timer-square'>
                        <h2>:</h2>
                        <p></p>
                    </div>

                    <div className='bootcamp-countdown-timer-square'>
                        <h2>{timeLeft.hours}</h2>
                        <p>HOURS</p>
                    </div>

                    <div className='bootcamp-countdown-timer-square'>
                        <h2>:</h2>
                        <p></p>
                    </div>

                    <div className='bootcamp-countdown-timer-square'>
                        <h2>{timeLeft.minutes}</h2>
                        <p>MINUTES</p>
                    </div>

                    <div className='bootcamp-countdown-timer-square'>
                        <h2>:</h2>
                        <p></p>
                    </div>

                    <div className='bootcamp-countdown-timer-square'>
                        <h2>{timeLeft.seconds}</h2>
                        <p>SECONDS</p>
                    </div>
                </>

            )}
        </div>
    );
};

export default CountdownTimer;
